import React  from 'react'

import {Layout} from '../components/Layout'
import BannerLanding from '../components/BannerLanding'
import { navigate } from "gatsby"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

class Landing extends React.Component {

    render() {
        const { location } = this.props; 

        return (
               <React.Fragment>
                    <Layout>
                    
                    { (location.state !== undefined && location.state !== null && (typeof window !== 'undefined') ) ? 
                    <div>
                        <BannerLanding  title={(location.state.album !== undefined) ? location.state.album.seccionTitle : '' } 
                            description={(location.state.album !== undefined) ? location.state.album.description: ''}
                            cover={(location.state.album !== undefined) ? location.state.album.cover: ''} />

                        <section id="landing-two" className="landing-tiles">
                            {(location.state.album !== undefined && (typeof window !== 'undefined') ) ? 
                                location.state.album.album.map( (item , index) => (
                                <GatsbyImage key={location.state.album.id+index}
                                 fluid={item} 
                                 className="image" />                           
                            )) : 
                            navigate('/#')
                            }                 
                        </section>
                    </div>
                        :  (typeof window !== 'undefined') ? navigate('/#') : ''
                    }

                    </Layout>
                </React.Fragment>
                );
    }

}

export default Landing;
